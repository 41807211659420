export default function LoginHeader(props :{showTitle : boolean}) {
  return (
    <header className="bg-white border-b-2 h-16 flex-shrink-0">
      <nav
        aria-label="Global"
        className="mx-2 flex justify-center items-center p-4 lg:px-8 h-full"
      >
        {props.showTitle ? 
        <div className="text-center text-fastcodetitlecolor tracking-wide text-3xl font-semibold">
          PERFORMANCE MONITOR
        </div> : null}
      </nav>
    </header>
  );
}
