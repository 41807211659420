import { useState, createContext, ReactNode, useEffect } from "react";
import {
  IUser,
  IUpdatePasswordRequest,
  userService,
  IUserRequest,
} from "../services/user-service";
import { useAxiosInstance } from "../services/base-service";
import { getUserStore } from "./user-store";
import { boolean, z } from "zod";
import { formChangePassword } from "@/components/elements/changePassword";
import { formCreateUSer } from "@/components/elements/createUser";

//Define the interface for UserContext
interface IUserContext {
  userData: IUser[];
  spinner: boolean;
  getTenant: (tenant: string) => void;
  changePassword: (
    data: z.infer<typeof formChangePassword>,
    username: string
  ) => Promise<{ status: number; message: string }>;
  createUser: (
    data: z.infer<typeof formCreateUSer>
  ) => Promise<{ status: number; message: string }>;
  updateEnabled: (data: {
    username: string;
    enabled: boolean;
  }) => Promise<{ status: number; message: string }>;
}

//UserContext and UserProvider work together
//Initialize UserContext
const UserContext = createContext<IUserContext | undefined>(undefined);

//Define UserProvider
const UserProvider = ({ children }: { children: ReactNode }) => {
  const { useAuthSelectors } = getUserStore();
  const { tenant, isSuperAdmin } = useAuthSelectors();

  const axiosInstance = useAxiosInstance();
  const userServiceInstance = userService(axiosInstance);

  //Using a state where data is going to be stored
  const [userData, setUserData] = useState<IUser[]>([]);
  //State of data to fetch
  const [fetchTenant, setFetchTenant] = useState<string>(
    isSuperAdmin ? "" : tenant!
  );
  // State for spinner on change button
  const [spinner, setSpinner] = useState<boolean>(false);

  //Callback we need to update the tenant to fetch (used in tenantSelection.tsx)
  const getTenant = (tenant: string) => {
    setFetchTenant(tenant);
  };

  //Execute getUsers at first rendering and also when fetchTenant changes
  useEffect(() => {
    getUsers(fetchTenant)
      .then((users) => setUserData(users))
      .catch((error) => {
        console.error(`Something went wrong - getUsers(), ${error}`);
      });
  }, [fetchTenant]);

  //Try to fetch the users from the database, the users to fetch are defined by the specified tenant
  //Function is called inside useEffect()
  async function getUsers(tenant?: string): Promise<IUser[]> {
    try {
      // Print to console that is fetching data
      console.debug(`users.getUsers(${tenant}) - fetching users from API`);
      // Fetching data using UserService instance
      const response = await userServiceInstance.getUsers(tenant);
      // setUserData(response.data);
      console.debug(`users.getUsers - data fetched`);
      return response.data;
    } catch (error: any) {
      //error management
      console.error(`users.getUsers - error fetching data`);
      if (error.response) {
        console.error(
          `users.getUsers - request made and response received, logging error.response`
        );
        console.error(error.response);
      } else if (error.request) {
        console.error(
          `users.getUsers - request made but no response received, logging error.request`
        );
        console.error(error.request);
      } else {
        console.error(
          `users.getUsers - error setting up the request, logging error.message`
        );
        console.error("Error", error.message);
      }
      return [];
    }
  }

  //Function that request to update the password
  async function changePassword(
    data: z.infer<typeof formChangePassword>,
    username: string
  ): Promise<{ status: number; message: string }> {
    setSpinner((spinner) => !spinner);
    const requestData: IUpdatePasswordRequest = {
      username: username,
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };

    try {
      // Print to console that is trying to change password
      console.debug(
        `users.changePassword(${requestData}) - trying to change password`
      );
      // request the api using UserService instance and updatePassword method
      const response = await userServiceInstance.updatePassword(requestData);
      console.debug(`users.changePassword - request made`);
      setSpinner((spinner) => !spinner);
      return {
        status: response.status,
        message: "Password changed with success.",
      };
    } catch (error: any) {
      //error management
      console.error(`users.changePassword - error fetching data`);
      if (error.response) {
        console.error(
          `users.changePassword - request made and response received, logging error.response`
        );
        console.error(error.response);
      } else if (error.request) {
        console.error(
          `users.changePassword - request made but no response received, logging error.request`
        );
        console.error(error.request);
      } else {
        console.error(
          `users.changePassword - error setting up the request, logging error.message`
        );
        console.error("Error", error.message);
      }
      setSpinner((spinner) => !spinner);
      return {
        status: error.response.status,
        message: "Password is not correct, please check again.",
      };
    }
  }

  async function createUser(
    data: z.infer<typeof formCreateUSer>
  ): Promise<{ status: number; message: string }> {
    setSpinner((spinner) => !spinner);
    const requestData: IUserRequest = {
      username: data.username,
      password: data.password,
      role: data.role,
      tenant: data.tenant,
      enabled: data.enabled,
    };

    try {
      // Print to console that is trying to create user
      console.debug(
        `users.createUser(${requestData}) - trying to change password`
      );
      // request the api using UserService instance and createUser method
      const response = await userServiceInstance.createUser(requestData);
      console.debug(`users.createUser - request made`);
      setSpinner((spinner) => !spinner);
      return { status: response.status, message: "User created with success." };
    } catch (error: any) {
      //error management
      console.error(`users.createUser - error fetching data`);
      if (error.response) {
        console.error(
          `users.createUser - request made and response received, logging error.response`
        );
        console.error(error.response);
      } else if (error.request) {
        console.error(
          `users.createUser - request made but no response received, logging error.request`
        );
        console.error(error.request);
      } else {
        console.error(
          `users.createUser - error setting up the request, logging error.message`
        );
        console.error("Error", error.message);
      }
      setSpinner((spinner) => !spinner);
      return {
        status: error.response.status,
        message: "Request is not correct, please check again.",
      };
    }
  }

  async function updateEnabled(data: {
    username: string;
    enabled: boolean;
  }): Promise<{ status: number; message: string }> {
    setSpinner((spinner) => !spinner);
    const requestData: IUserRequest = {
      username: data.username,
      enabled: data.enabled,
    };

    try {
      // Print to console that is trying to create user
      console.debug(
        `users.updateEnabled(${requestData}) - trying to change password`
      );
      // request the api using UserService instance and updateEnabled method
      const response = await userServiceInstance.updateEnabled(requestData);

      // Se la modifica è andata bene aggiorno l'oggetto userData
      setUserData(
        userData.map((user) =>
          user.name === requestData.username
            ? { ...user, enabled: requestData.enabled }
            : { ...user }
        ) as IUser[]
      );
      setSpinner((spinner) => !spinner);
      return {
        status: response.status,
        message: response.data.value.message as string,
      };
    } catch (error: any) {
      //error management
      console.error(`users.updateEnabled - error fetching data`);
      if (error.response) {
        console.error(
          `users.updateEnabled - request made and response received, logging error.response`
        );
        console.error(error.response);
      } else if (error.request) {
        console.error(
          `users.updateEnabled - request made but no response received, logging error.request`
        );
        console.error(error.request);
      } else {
        console.error(
          `users.updateEnabled - error setting up the request, logging error.message`
        );
        console.error("Error", error.message);
      }
      setSpinner((spinner) => !spinner);
      return {
        status: error.response.status,
        message: "La modifica non è andata a buon fine, riprova.",
      };
    }
  }

  return (
    <>
      <UserContext.Provider
        value={{
          userData,
          spinner,
          getTenant,
          changePassword,
          createUser,
          updateEnabled,
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  );
};

export { UserContext, UserProvider };
