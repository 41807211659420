import { useState, createContext, ReactNode, useEffect } from "react";
import {
  Report,
  Dashboard,
  EmbedConfig,
  powerBIService,
} from "@/services/powerbi-service";
import { useAxiosInstance } from "../services/base-service";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

//Define the interface for DashboardContext
interface IDashboardContext {
  reports: Report[];
  embedConfig: EmbedConfig;
  setReportSummary: (reportId: string, value: string) => Promise<Report>;
}

const DashboardContext = createContext<IDashboardContext | undefined>(
  undefined
);

const DashboardProvider = ({ children }: { children: ReactNode }) => {
  const axiosInstance = useAxiosInstance();
  const powerbiServiceInstance = powerBIService(axiosInstance);
  
  const [reports, setReports] = useState<Report[]>([]);
  const navigate = useNavigate();
  
  const params = useParams().displayname;
  const [reportCodeForSearch, setReportCodeForSearch] = useState<string | undefined>(params);
  useEffect(()=> {setReportCodeForSearch(params)},[params])

  const [embedConfig, setEmbedConfig] = useState<EmbedConfig>(
    {} as EmbedConfig
  );

  async function getReports(): Promise<Report[]> {
    try {
      console.debug(`powerbi.getReports - fetching reports from API`);
      const response = await powerbiServiceInstance.getReports();

      console.debug(`powerbi.getReports - reports fetched`);

      return response.data;
    } catch (error: any) {
      //error management
      console.error(`powerbi.getReports - error fetching data`);
      if (error.response) {
        console.error(
          `powerbi.getReports - request made and response received`
        );
        console.error(error.response);
      } else if (error.request) {
        console.error(
          `powerbi.getReports - request made but no response received`
        );
        console.error(error.request);
      } else {
        console.error(`powerbi.getReports - error setting up the request`);
        console.error("Error", error.message);
      }
      return [];
    }
  }

  async function setReportSummary(
    reportId: string,
    value: string
  ): Promise<Report> {
    try {
      console.debug(`trying to powerbi.setReportSummary`);

      const encodeValue = btoa(value);
      const response = await powerbiServiceInstance.setReportSummary(
        reportId,
        encodeValue
      );
      setEmbedConfig({ ...embedConfig, summary: atob(response.data.summary!) });

      console.debug(`powerbi.setReportSummary - data fetched`);
      return response.data;
    } catch (error: any) {
      //error management
      console.error(`powerbi.setReportSummary - error fetching data`);
      if (error.response) {
        console.error(
          `powerbi.setReportSummary - request made and response received`
        );
        console.error(error.response);
      } else if (error.request) {
        console.error(
          `powerbi.setReportSummary - request made but no response received`
        );
        console.error(error.request);
      } else {
        console.error(
          `powerbi.setReportSummary - error setting up the request`
        );
        console.error("Error", error.message);
      }
      return Promise.reject(error.request);
    }
  }

  async function embedReport(reportId: string): Promise<EmbedConfig> {
    try {
      console.debug(`trying to powerbi.embedReport`);

      const response = await powerbiServiceInstance.embedReport(reportId);

      console.debug(`powerbi.embedReport - embed fetched`);
      const emb: EmbedConfig = {
        artifactId: response.data.artifactId,
        artifactType: response.data.artifactType,
        displayName: response.data.displayName,
        product: response.data.product,
        category: response.data.category,
        webUrl: response.data.webUrl,
        embedUrl: response.data.embedUrl,
        summary: (response.data.summary && atob(response.data.summary)) || "", // faccio subito il decode del summary nel momento in cui lo ricevo
        tokenId: response.data.tokenId,
        token: response.data.token,
        expiration: response.data.expiration,
      };

      return emb;
    } catch (error: any) {
      //error management
      console.error(`powerbi.embedReport - error fetching data`);
      if (error.response) {
        console.error(
          `powerbi.embedReport - request made and response received`
        );
        console.error(error.response);
      } else if (error.request) {
        console.error(
          `powerbi.embedReport - request made but no response received`
        );
        console.error(error.request);
      } else {
        console.error(`powerbi.embedReport - error setting up the request`);
        console.error("Error", error.message);
      }
      return Promise.reject(error.request);
    }
  }

  async function getEmbedConfig(code: string) {
    try {
      var reportId = reports.find((report) => {// controllo il parametro di input tra i report che l'utente può vedere 
        if (report.code === code) return report;
      })?.id;  

      // se non va a buon fine, rimando il caricamento con il primo report  
      if(!reportId){
        console.error(`Nessuna dashboard trovata per ${code}`);
        navigate(`/dashboards/${reports[0].code}`);
      }
      else {
        var newEmbed = await embedReport(reportId)
        setEmbedConfig(newEmbed);
      }

    } catch (error: any) {
        console.error(`error during call to powerbi.embedReport, retry`);
        getEmbedConfig(code); 
    }
  }

  // al mount carico le dashboards da mostrare nella dropdown di selezione
  useEffect(() => {
    getReports()
      .then((res) => setReports(res))
      .catch((error) => {
        console.error(`Something went wrong - getReports(), ${error}`);
      });
  }, []);

  // al caricamento dei report faccio il primo embed di default
  useEffect(() => {
    if (reports.length === 0) {// se non ho i reports non faccio nulla
      console.debug("trying to get the first report");
      return;
    }

    if (reportCodeForSearch) {// se ho il parametro nell'url ("/dashboards/...") prendo questo per la call all'api
      getEmbedConfig(reportCodeForSearch);
    } else {// gestisco il caso in cui non ho il parametro ("/dashboards") rimandando la pagina con il primo report
      console.debug("no name given, take the first report");
      navigate(`/dashboards/${reports[0].code}`);
    }
  }, [reports, reportCodeForSearch]);

  return (
    <>
      <DashboardContext.Provider
        value={{ reports, embedConfig, setReportSummary }}
      >
        {children}
      </DashboardContext.Provider>
    </>
  );
};

export { DashboardContext, DashboardProvider };
