import { AxiosInstance } from "axios";
import { BaseService } from "./base-service";

export interface IUser {
    name: string;
    role: string;
    tenant: string;
    enabled: boolean;
    creationDate: Date;
    lastAccessedOn: Date;
}

export interface IUpdatePasswordRequest {
    username: string;
    oldPassword: string;
    newPassword: string;
}

export interface IUserRequest {
    username: string;
    password?: string;
    role?: string;
    tenant?: string;
    enabled?: boolean;
}

class UserService extends BaseService {
    getUsers(tenant?: string) {
        return this.instance.get<IUser[]>(`user/users/${tenant}`);
    }

    updatePassword(request: IUpdatePasswordRequest) {
        return this.instance.patch('user/update-password', request);
    }

    createUser(request:IUserRequest){
        return this.instance.post('user/create-user', request);
    }

    updateEnabled(request: IUserRequest) {
        return this.instance.patch('user/update-enabled', request);
    }
}

//Function to call for getting a UserService object (see users.tsx)
export const userService = (axiosInstance: AxiosInstance) => new UserService(axiosInstance);