import Footer from "@/components/elements/footer";
import UserTable from "@/components/elements/user-table";
import { UserProvider } from "@/stores/users";
import Header from "../elements/headerUsers";

function Users() {
  return (
    <>
      <Header />
      <div className="flex flex-col ">
        <div className="flex-grow flex flex-col items-center pt-8 space-y-4 mb-8 overflow-y-auto">
          <div className="w-full max-w-screen-lg px-4">
            {/* wrap the UserTable to get access UserProvider properties */}
            <UserProvider>
              <UserTable />
            </UserProvider>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Users;
