import FormLogin from "@/components/elements/formLogin";
import Footer from "@/components/elements/footer";
import LoginHeader from "../elements/headerLogin";

function Login() {
  return (
    <div className="flex flex-col min-h-screen">
      <LoginHeader showTitle/>  
      <div className="flex-grow flex flex-col items-center pt-10 space-y-4 mb-10">
        <FormLogin />
      </div>
      <Footer />
    </div>
  );
}

export default Login;
