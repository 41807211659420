import { Input } from "@/components/shadcn/ui/input";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/shadcn/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthDispatch, setAuthentication } from '@/stores/user-store';
import { authService, AuthRequest } from '@/services/auth-service';
import { useState } from "react";
import { Button } from "../shadcn/ui/button";
import Spinner from "./spinner";

//Error messages schema, in case username/password is empty
const formSchema = z.object({
    username: z.string().min(1, {
        message: "Lo username non può essere vuoto",
    }),
    password: z.string().min(1, {
        message: "La password non può essere vuota",
    }),
});


function FormLogin() {
    //Initialize base values with empty string
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            username: "",
            password: "",
        },
    });

    const dispatch = useAuthDispatch();
    const navigate = useNavigate();

    // leggo il parametro passato dal router tramite state per continuare la navigazione sull'url desiderato
    const { state: locationState } = useLocation();

    //state of error messages
    const [errorMessage, setErrorMessage] = useState<string>("");
    //Stato del caricamento login
    const [isLoading, setIsLoading] = useState<boolean>(false)


    async function onSubmit(data: z.infer<typeof formSchema>) {
        const authRequest: AuthRequest = { username: data.username, password: data.password };

        try {
            //When login button is clicked, the isLoading state changes (should spin)
            setIsLoading(isLoading => !isLoading)
            //Awaiting for server response
            const response = await authService.authenticate(authRequest);
            const authData = response.data;
            //Set the state of authorization data
            setAuthentication(dispatch, authData);
            // navigazione   
            locationState ? navigate(locationState.redirectTo) : navigate('/dashboards')
        } catch (err) {
            //In case of error the state change again (the spin stop spinning)
            setIsLoading(isLoading => !isLoading)
            setErrorMessage("Verificare le credenziali e ritentare");
        }
    }

    return (
        <div className="flex items-start justify-center mt-10 border border-gray-300 rounded-md p-4 pt-6 pb-6">
            <Form {...form}>
                 <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col p-6 gap-4">
                    <FormField
                        control={form.control}
                        name="username"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className="w-[300px]"
                                        placeholder="Username"
                                        {...field}
                                        onBlur={() => form.trigger("username")}
                                        onFocus={() => form.clearErrors("username")}
                                    />
                                </FormControl>
                                <div className="h-3">
                                    <FormMessage className="text-red-500" />
                                </div>
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className="w-[300px]"
                                        type="password"
                                        placeholder="Password"
                                        {...field}
                                        onBlur={() => form.trigger("password")}
                                        onFocus={() => form.clearErrors("password")}
                                    />
                                </FormControl>
                                <div className="h-3">
                                    <FormMessage className="text-red-500" />
                                </div>
                            </FormItem>
                        )}
                    />
                    <Button className="w-1/2 self-center" type="submit" variant="outline">
                        {isLoading ? <Spinner/> : "Login"}
                    </Button>
                    
                    {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                </form>
            </Form>
        </div>
    );
}

export default FormLogin;
