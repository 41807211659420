import * as React from "react";
import { RxCaretSort } from "react-icons/rx";
import { ColumnDef, ColumnFiltersState, SortingState, VisibilityState, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Button } from "@/components/shadcn/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/shadcn/ui/table";
import { TenantSelection } from "@/components/elements/tenantSelection";
import { UserContext } from "@/stores/users";
import { IUser } from "@/services/user-service";
import { getUserStore } from "@/stores/user-store";
import useTenantStyles from "./useTenantStyles";
import CreateUser from "./createUser";
import ChangePassword from "./changePassword";
import EnableConfirm from "./updateEnabled";

// set the column structure
export const columns: ColumnDef<IUser>[] = [
  {
    accessorKey: "tenant",
    header: "Tenant",
    cell: ({ row }) => (
      <div className="text-center px-2 text-blacktextcell">{row.getValue("tenant") as string}</div>
    ),
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        className="w-full flex justify-center"
      >
        Name
        <RxCaretSort className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => (
      <div className="text-center px-2">{row.getValue("name") as string}</div>
    ),
  },
  {
    accessorKey: "role",
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        className="w-full flex justify-center"
      >
        Role
        <RxCaretSort className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => (
      <div className="text-center px-2">{row.getValue("role") as string}</div>
    ),
  },
  {
    accessorKey: "enabled",
    header: "Enabled",
    cell: ({ row }) => (
      <div className="text-center px-2">
        {row.getValue("enabled") ? "Yes" : "No"}
      </div>
    ),
  },
  {
    accessorKey: "lastAccessedOn",
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        className="w-full flex justify-center"
      >
        Last Access
        <RxCaretSort className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => {
      const value = row.getValue("lastAccessedOn") as string | number | Date;
      const dateValue = value instanceof Date ? value : new Date(value);
      return (
        <div className="text-center px-2">{dateValue.toLocaleDateString()}</div>
      );
    },
  },
  {
    accessorKey: "creationDate",
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        className="w-full flex justify-center"
      >
        Creation Date
        <RxCaretSort className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => {
      const value = row.getValue("creationDate") as string | number | Date;
      const dateValue = value instanceof Date ? value : new Date(value);
      return (
        <div className="text-center px-2">{dateValue.toLocaleDateString()}</div>
      );
    },
  },
  {
    id: "actions",
    header: "Actions",
    cell: ({ row }) => {
      const user = row.original as IUser;

      return (
        <div className="flex space-x-2 justify-center px-2">
          <EnableConfirm username={user.name} enabled={user.enabled}/>
          <ChangePassword username={user.name}/>
        </div >
      );
    },
  },
];

export function UserTable() {

  //In case tenant change, automatically color variables change in base of tenant logged
  useTenantStyles();

  // use the context declared in users.tsx
  const userContext = React.useContext(UserContext);
  //control if userContext exists and is not null
  if (!userContext) {
    throw new Error("UserContext must be used within UserProvider");
  }

  //get userData from userContext
  const { userData } = userContext;

  const { useAuthSelectors } = getUserStore();
  const { tenant, isSuperAdmin, isAdmin } = useAuthSelectors();

  
  //state of selected tenant (initial state depends on user's role)
  const [selectedTenant, setSelectedTenant] = React.useState<string>(isSuperAdmin ? "All" : tenant?.charAt(0)! + tenant?.slice(1).toLowerCase());
  // state for saving sorting changes
  const [sorting, setSorting] = React.useState<SortingState>([]);
  
  const [myColumns, setColumns] = React.useState<ColumnDef<IUser>[]>(columns)
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  
  //Makes possible to remove tenant from the column if the user is an Admin
  React.useEffect(() => {
    //if isAdmin is true return an array without tenant column, if not return the default columns
    setColumns(columns.filter((value: any) => isAdmin ? value.accessorKey != "tenant" : value))
  }, [isAdmin])

  
  const handleTenantSelect = (value: string) => {
    setSelectedTenant(value);
  };

  const table = useReactTable({
    data: userData,
    columns: myColumns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    initialState: {
      pagination: {
        pageSize: 6, // Utenti massimi in una pagina
      },
    },
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="w-full overflow-x-auto">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <TenantSelection
            onSelect={handleTenantSelect}
            selectedValue={selectedTenant}
          />
        </div>
        <div>
          <CreateUser/>
        </div>
      </div>
      <div className="border-bordermenu rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow className="border-bordermenu " key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="text-center hover:bg-bghoverbutton">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  className="border-bordermenu hover:bg-bghoverbutton"
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className="text-center"
                      style={{ minWidth: "135px" }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={myColumns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="text-sm text-muted-foreground text-center">
          {table.getFilteredRowModel().rows.length} users found.
        </div>
        <div className="space-x-2">
          <Button
            className="border-bordermenu hover:bg-bghoverbutton"
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            className="border-bordermenu hover:bg-bghoverbutton"
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>

    </div>
    
  );
}

export default UserTable;