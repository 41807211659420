import Header from "../elements/headerDashboards";
import Monitor from "../elements/monitor";
import { useState, useEffect } from "react";


function Dashboard() {

  const [monitorVisible, setMonitorVisile] = useState<boolean>(false); 

    return (<>
      <Header setMonitorVisile={setMonitorVisile}/>     
      <Monitor visible={monitorVisible} setMoniotrVisible={setMonitorVisile}/>  
    </>)
}

export default Dashboard;