import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/shadcn/ui/button";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/components/shadcn/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/shadcn/ui/popover";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "@/stores/users";
import useTenantStyles from "./useTenantStyles";

export type FormSelectionElement = {
  code: string;
  name: string;
};

type FormSelectionProps = {
  onSelect: (value: string) => void;
  allSelection: FormSelectionElement[];
};

export function FormSelection({ onSelect, allSelection }: FormSelectionProps) {
  //In case tenant change, automatically color variables change in base of tenant logged
  useTenantStyles();

  //tenant select menu state
  const [open, setOpen] = useState(false);
  //state of the selected value inside the dropdown menu (select menu)
  const [selectedValue, setRoleValue] = useState<string>(allSelection[0].name);

  //Here we use the UserContext we declared in users.tsx
  const userContext = useContext(UserContext);
  //make sure that userContext exist and is not null
  if (!userContext) {
    throw new Error("UserContext must be used within UserProvider");
  }

  //in case the tenantToFetch changes automatically update states
  useEffect(() => {
    setOpen(false);
  }, [selectedValue]);

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="hover:bg-bghoverbutton w-[140px] justify-between text-sm font-normal"
          >
            {selectedValue}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        {/* if user role is SUPERADMIN render the select menu of tenants */}

        <PopoverContent className="w-[140px] p-0">
          <Command>
            <CommandList>
              <CommandGroup>
                {/* render select menu options with avaiable tenants */}
                {allSelection.map((selected) => (
                  <CommandItem
                    className="text-sm font-normal"
                    key={selected.code}
                    value={selected.name}
                    // Anonim function that update tenant to render and the tenant to fetch
                    onSelect={() => {
                      setRoleValue(selected.name);
                      onSelect(selected.name);
                    }}
                  >
                    {selected.name}
                    <CheckIcon
                      // when tenant.value exists add a new class in className attribute
                      className={`ml-auto h-4 w-4 ${
                        selectedValue === selected.name
                          ? "opacity-100"
                          : "opacity-0"
                      }`}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
}
