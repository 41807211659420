import { useState, useContext, useEffect, useCallback } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { getUserStore } from "@/stores/user-store";
import { DashboardContext } from "@/stores/dashboards";
import LoggedUser from "./loggedUser";
import useTenantStyles from "./useTenantStyles";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/ui/popover";
import { Input } from "../shadcn/ui/input";
import { ChevronDown } from "lucide-react";
import Spinner from "./spinner";

const logos: { [key: string]: string } = {
  aruba: "/images/aruba-logo.png",
  fastcode: "/images/fastcode-logo.png",
};

export default function Header(props: {
  setMonitorVisile?: (b: boolean) => void;
}) {
  const { setMonitorVisile } = props;

  const dashboardContext = useContext(DashboardContext);
  if (!dashboardContext) {
    throw new Error("DashboardContext must be used within DashboardProvider");
  }
  const { embedConfig, reports } = dashboardContext;

  const [titleDashboard, setTitle] = useState("Dashboards");

  const { useAuthSelectors } = getUserStore();
  const { tenant } = useAuthSelectors();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  let locationPath = useLocation();
  const [location] = useState(locationPath);
  const navigate = useNavigate();

  useEffect(() => {
    setTitle(embedConfig.displayName!);
  }, [embedConfig]);

  // Stile e logo in base al tenant
  useTenantStyles();
  const logoUrl = tenant
    ? logos[tenant.toLowerCase()] || "/assets/images/default-logo.png"
    : "/assets/images/default-logo.png";

  // Ricerca dalla dropdown
  const [searchQuery, setSearchQuery] = useState("");
  const filteredReports = reports.filter((item) =>
    item.displayName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <header className="bg-white border-b-2 h-16 flex-shrink-0">
      <nav
        aria-label="Global"
        className="flex  items-center p-4 justify-between "
      >
        <div className="flex items-center space-x-4 sm:divide-x sm:divide divide-gray-500">
          {/* Logo */}
          <a className="">
            <span className="sr-only">Your Company</span>
            <img
              alt="company-logo"
              src={logoUrl}
              className="h-8 w-auto hidden sm:block"
            />
          </a>
          {/* Popover per Dashboards */}
          <div className="flex ">
            <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
              <>
                {/* Trigger per aprire la finestra con le Dashboards */}
                <div className="">
                  <PopoverTrigger asChild className="">
                    <button
                      onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                      className={"flex items-center text-sm gap-x-1 font-normal text-titlecolor ml-0.5 sm:ml-6"}
                    >
                      <>
                        {titleDashboard ?? "Dashboards"}
                        <ChevronDown
                          aria-hidden="true"
                          className="flex h-3 w-3 flex-none text-titlecolor"
                        />
                      </>
                    </button>
                  </PopoverTrigger>
                </div>

                {/* Componente che contiene SearchBar e Dashboards */}
                <PopoverContent
                  className="w-[280px]  z-[9998] p-2 max-h-[20rem]  rounded-md  border-bordermenu bg-white text-zinc-950 shadow-lg ring-1 ring-gray-900/5"
                  onInteractOutside={() => setIsPopoverOpen(false)}
                >
                  {reports.length > 6 ? ( // La search bar compare quando il numero di dashboard raggiunge un certo limite
                    <div className="sticky top-0">
                      <Input
                        type="text"
                        placeholder="Cerca Dashboard"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="mb-2 p-2 border-b-2 "
                      />{" "}
                    </div>
                  ) : null}
                  <div className="overflow-auto max-h-[15rem]">
                    {filteredReports.length > 0 ? (
                      // Map per generare singoli elementi per selezione dashboard
                      <div>
                        {filteredReports.map((item) => (
                          <button
                            key={item.id}
                            disabled={item.id === embedConfig.artifactId}
                            onClick={() => {
                              // se il metodo setMonitorVisile viene passato (poichè nelle props puo essere omesso)
                              {
                                setMonitorVisile && setMonitorVisile(false);
                              } //metto il visibleMonitor a false
                              navigate(`/dashboards/${item.code}`);
                              setTitle(item.displayName);
                              setIsPopoverOpen(false); // Chiudi il Popover
                            }}
                            className="flex items-center w-full p-2 text-left text-sm leading-6 text-gray-600 transition duration-200 hover:bg-gray-200 rounded-lg"
                          >
                            <div className="flex-auto">
                              <p className="font-normal">{item.displayName}</p>
                            </div>
                          </button>
                        ))}
                      </div>
                    ) : (
                      //se le dashboard (chiamate nel context) non sono caricate, mostro lo spinner
                      <div className="flex items-center w-full p-2">
                        <Spinner />
                      </div>
                    )}{" "}
                  </div>
                </PopoverContent>

                {/* Overlay dietro il Popover */}
                {isPopoverOpen && (
                  <div // altrimenti il click sul componente Monitor non chiude il Popover
                    className="fixed inset-0 z-[9998]"
                    onClick={() => setIsPopoverOpen(false)}
                  />
                )}
              </>
            </Popover>
          </div>
        </div>
        {/* Elemento per avatar alla fine dell'header */}
        <div className="flex justify-end">
          <LoggedUser />
        </div>
      </nav>
    </header>
  );
}
