import { useState, useContext, useEffect, useCallback } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { getUserStore } from "@/stores/user-store";
import LoggedUser from "./loggedUser";
import useTenantStyles from "./useTenantStyles";

const logos: { [key: string]: string } = {
  aruba: "/images/aruba-logo.png",
  fastcode: "/images/fastcode-logo.png",
};

export default function Header() {
  const { useAuthSelectors } = getUserStore();
  const { tenant } = useAuthSelectors();

  // Stile e logo in base al tenant
  useTenantStyles();
  const logoUrl = tenant
    ? logos[tenant.toLowerCase()] || "/assets/images/default-logo.png"
    : "/assets/images/default-logo.png";

  return (
    <header className="bg-white border-b-2 h-16 flex-shrink-0">
      <nav
        aria-label="Global"
        className="flex  items-center p-4 justify-between "
      >
        <div className="flex items-center space-x-4 sm:divide-x sm:divide divide-gray-500">
          {/* Logo */}
          <a className="">
            <span className="sr-only">Your Company</span>
            <img
              alt="company-logo"
              src={logoUrl}
              className="h-8 w-auto hidden sm:block"
            />
          </a>
          {/* Popover per Dashboards */}
          <div className="flex ">
            <Link to="/dashboards">
              <span className="flex items-center text-sm gap-x-1 font-normal hover:text-titlecolor ml-0.5 sm:ml-6">Dashboards</span>
            </Link>
          </div>
        </div>
        {/* Elemento per avatar alla fine dell'header */}
        <div className="flex justify-end">
          <LoggedUser />
        </div>
      </nav>
    </header>
  );
}
