import React, { createContext, useContext, useReducer, ReactNode } from "react";
import { AuthResponse, authService } from "@/services/auth-service";

// Definisco l'interfaccia dello stato
export interface AuthState {
  username: string | null;
  role: string | null;
  tenant: string | null;
  jwt: string | null;
  expires: Date | null;
}

// Definisco lo stato iniziale
const initialState: AuthState = {
  username: null,
  role: null,
  tenant: null,
  jwt: null,
  expires: null,
};

// Definisco le azioni
export type AuthAction =
  | { type: "CLEAR_STATE" }
  | { type: "SET_AUTHENTICATION"; payload: AuthResponse }
  | { type: "REFRESH_TOKEN"; payload: AuthResponse };

// Definisco il reducer
function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case "CLEAR_STATE":
      console.debug(`user store - authentication state cleared`);

      return { ...initialState };
    case "SET_AUTHENTICATION":

      return { ...state, ...action.payload };
    case "REFRESH_TOKEN": 

      return { ...state, ...action.payload };
    default:
      const invalidAction: never = action;
      throw new Error(`Unhandled action type: ${invalidAction}`);
  }
}

// Crea i contesti per lo stato e il dispatch
const AuthStateContext = createContext<AuthState | undefined>(undefined);
const AuthDispatchContext = createContext<
  React.Dispatch<AuthAction> | undefined
>(undefined);

// Crea un componente provider per lo stato e il dispatch
export const AuthProvider = ({
  children,
  setAuthentication,
}: {
  children: ReactNode;
  setAuthentication: (
    dispatch: React.Dispatch<AuthAction>,
    data: AuthResponse
  ) => void;
}) => {
  
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export const useAuthState = (): AuthState => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within an AuthProvider");
  }
  return context;
};

// Crea un hook per l'utilizzo dello stato e del dispatch
export const useAuthDispatch = (): React.Dispatch<AuthAction> => {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within an AuthProvider");
  }
  return context;
};

// funzione per l'invio di azioni
export const clearState = (dispatch: React.Dispatch<AuthAction>) => {
  dispatch({ type: "CLEAR_STATE" });
};

export const setAuthentication = (
  dispatch: React.Dispatch<AuthAction>,
  data: AuthResponse
) => {
  if (data.jwt && data.expires && new Date(data.expires) < new Date()) {
    // Token scaduto, effettua il refresh
    refreshToken(dispatch);
  } else {
    // Token valido, imposta l'autenticazione
    dispatch({ type: "SET_AUTHENTICATION", payload: data });
  }
};

// Funzione per il refresh del token
async function refreshToken(dispatch: React.Dispatch<AuthAction>) {
  try {
    const response = await authService.refreshToken();
    dispatch({ type: "REFRESH_TOKEN", payload: response.data });
  } catch (error) {
    console.error("Error refreshing token", error);
    // Esegui azioni in base all'errore di aggiornamento del token
  }
}

// getters
export const useAuthSelectors = () => {
  const state = useAuthState();
  return {
    isAuthenticated: !!state.username && !!state.role && !!state.jwt,
    isSuperAdmin: state.role === "Super Admin",
    isAdmin: state.role === "Admin" && !!state.tenant,
    isUser: state.role === "User" && !!state.tenant,
    tenant: state.tenant,
  };
};

export const getUserStore = () => {
  return {
    useAuthState,
    useAuthDispatch,
    clearState,
    setAuthentication,
    useAuthSelectors,
  };
};
