import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/shadcn/ui/button";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/components/shadcn/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/shadcn/ui/popover";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "@/stores/users";
import { getUserStore } from "@/stores/user-store";
import useTenantStyles from "./useTenantStyles";

type Tenant = {
  value: string;
  label: string;
};

const allTenants: Tenant[] = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Aruba",
    label: "Aruba",
  },
  {
    value: "Fastcode",
    label: "Fastcode",
  },
];

type TenantSelectionProps = {
  onSelect: (value: string) => void;
  selectedValue: string;
};

export function TenantSelection({
  onSelect,
  selectedValue,
}: TenantSelectionProps) {
  //In case tenant change, automatically color variables change in base of tenant logged
  useTenantStyles();

  //tenant select menu state
  const [open, setOpen] = useState(false);
  //state of the selected value inside the dropdown menu (select menu)
  const [tenantValue, setTenantValue] = useState<string>(selectedValue);
  const [tenantToFetch, setTenantToFetch] = useState<string>(
    selectedValue == "All" ? "" : selectedValue
  );

  const { useAuthSelectors } = getUserStore();
  const { tenant, isAdmin, isSuperAdmin } = useAuthSelectors();

  let tenants: Tenant[] = [];
  if (isSuperAdmin) {
    tenants = allTenants;
  } else if (isAdmin) {
    tenants = allTenants.filter((t) => t.value === tenant);
  }

  //Here we use the UserContext we declared in users.tsx
  const userContext = useContext(UserContext);
  //make sure that userContext exist and is not null
  if (!userContext) {
    throw new Error("UserContext must be used within UserProvider");
  }
  //destructoring userContext and obtain getTenant callback
  const { getTenant } = userContext;

  //in case the tenantToFetch changes automatically update states
  useEffect(() => {
    getTenant(tenantToFetch);
    onSelect(tenantValue);
    setOpen(false);
  }, [tenantToFetch]);

  return (
    <>
      {!isAdmin && <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="border-bordermenu hover:bg-bghoverbutton w-[150px] justify-between"
          >
            Tenant: {tenantValue}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        {/* if user role is SUPERADMIN render the select menu of tenants */}
        {isSuperAdmin && (
          <PopoverContent className="w-[150px] p-0">
            <Command>
              <CommandList>
                <CommandGroup>
                  {/* render select menu options with avaiable tenants */}
                  {tenants.map((tenant) => (
                    <CommandItem
                      key={tenant.value}
                      value={tenant.value}
                      // Anonim function that update tenant to render and the tenant to fetch
                      onSelect={() => {
                        setTenantValue(tenant.value);
                        setTenantToFetch(() => {
                          return tenant.value == "All" ? "" : tenant.value;
                        });
                      }}
                    >
                      {tenant.label}
                      <CheckIcon
                        // when tenant.value exists add a new class in className attribute
                        className={`ml-auto h-4 w-4 ${
                          tenantValue === tenant.value
                            ? "opacity-100"
                            : "opacity-0"
                        }`}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        )}
      </Popover>}
    </>
  );
}
