import { useContext, useEffect, useState } from "react";
import { CircleAlert, X } from "lucide-react";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
} from "../shadcn/ui/dialog";
import { getUserStore } from "@/stores/user-store";
import { DashboardContext } from "@/stores/dashboards";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Essentials,
  ClassicEditor,
  Paragraph,
  RemoveFormat,
  FindAndReplace,
  SourceEditing,
  GeneralHtmlSupport,
  FullPage,
  Heading,
  Font,
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  SpecialCharacters,
  SpecialCharactersEssentials,
  Code,
  Alignment,
  Link,
  AutoLink,
  Indent,
  IndentBlock,
  Table,
  TableToolbar,
  TableCaption,
  TableProperties,
  TableCellProperties,
  TableColumnResize,
  HorizontalLine,
  PasteFromOffice,
  EditorConfig,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";

function Wiki() {
  const dashboardContext = useContext(DashboardContext);
  if (!dashboardContext) {
    throw new Error("DashboardContext must be used within DashboardProvider");
  }
  const { embedConfig, setReportSummary } = dashboardContext; // Importo l'embed per il summary, il set per la sua modifica

  const [isConfirmOpen, setIsConfirmOpen] = useState(false); // Stato per il secondo Dialog, cioè la conferma prima della chiusura senza salvataggio
  const [isOpen, setIsOpen] = useState(false); // Stato per il Dialog principale
  const [saved, setSaved] = useState(false);
  const [editorSummary, setEditorSummary] = useState(""); // Stato per il contenuto dell'editor

  useEffect(() => {
    setEditorSummary(embedConfig.summary!);
    setSaved(false);
  }, [embedConfig]); // Ogni volta che cambia il report, cambio il testo mostrato nell'editor

  const { useAuthSelectors } = getUserStore();
  const { isSuperAdmin } = useAuthSelectors(); // Importo per controlli come permettere modifiche o save del summary

  // Funzione per chiudere il dialogo
  const onDialogClose = () => {
    if (embedConfig.summary! === editorSummary) {
      setIsOpen(false);
    } else saved ? setIsOpen(false) : setIsConfirmOpen(true);
  };

  // Funzione per salvare il summary
  const saveSummary = () => {
    try {
      setReportSummary(embedConfig.artifactId!, editorSummary);
      setSaved(true);
    } catch (error: any) {
      alert("Il salvataggio non è andato a buon fine");
    } finally {
      alert("Salvataggio completato");
    }
  };

  return (
    <div className="">
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        {/* Button per aprire il dialog */}
        <DialogTrigger className="btn">
          <CircleAlert className="w-5 h-5  text-gray-500 rotate-180" />
        </DialogTrigger>

        {/* Se la summary è vuota resituisco un secondo Dialog. Faccio il check anche sul role. Se il role è SA invece, deve poter entrare per scriverla*/}
        {editorSummary == "" && !isSuperAdmin ? (
          <EmptySummary onClick={setIsOpen} />
        ) : (
          <DialogContent
            className="dialog-content"
            customCloseButton
            onInteractOutside={(e) => {
              // Gestisce click sull'overlay
              e.preventDefault();
              onDialogClose();
            }}
          >
            <DialogTitle hidden>Editor</DialogTitle>
            <DialogDescription aria-describedby={undefined} />

            {/*Header del Dialog*/}
            <div className="flex items-center justify-center py-2 px-4 relative">
              {/* Name dell'embed a sinistra */}
              <div className="text-base flex-1 font-semibold text-gray-700">
                {embedConfig.displayName}
              </div>

              {/* Posiziona il pulsante Save al centro */}
              {isSuperAdmin && (
                <button
                  className={
                    embedConfig.summary! !== editorSummary
                      ? "absolute left-1/2 transform -translate-x-1/2 w-20 py-1 text-white bg-green-500 rounded  hover:bg-green-600"
                      : "absolute left-1/2 transform -translate-x-1/2 w-20 py-1 text-white bg-green-200 rounded "
                  }
                  onClick={saveSummary}
                  disabled={!(embedConfig.summary! !== editorSummary)}
                >
                  Save
                </button>
              )}

              {/* Pulsante X per la chiusura, a destra */}
              <div className="absolute right-4 flex-none rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2">
                <button onClick={onDialogClose}>
                  <X className="h-6 w-6" />
                  <span className="sr-only">Close</span>
                </button>
              </div>
            </div>  

            {/* Editor */}
            <div className="text-editor ">
              <CKEditor
                disabled={!isSuperAdmin} // disabled per le modifiche a meno che non sia SA
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  const data = editor.getData(); // Ottieni i dati dall'editor
                  setEditorSummary(data); // Aggiorna lo stato con il nuovo contenuto
                }}
                config={isSuperAdmin ? editorConfig() : {...editorConfig(), toolbar: []}}
                data={editorSummary}
              />
            </div>
          </DialogContent>
        )}
      </Dialog>

      {/* Secondo Dialog, per confermare prima di chiudere */}
      <Dialog open={isConfirmOpen} onOpenChange={setIsConfirmOpen}>
        <DialogContent
          customCloseButton
          className="sm:max-w-[400px] p-4 bg-white shadow-md rounded-md"
        >
          <DialogTitle hidden className="text-center">
            Attenzione
          </DialogTitle>
          <p className="text-base font-medium text-gray-700">
            Uscire senza prima salvare le modifiche?
          </p>
          <DialogDescription aria-describedby={undefined} />

          <div className="mt-3 flex justify-end space-x-1">
            <button
              className="px-3 py-1 bg-blue-500 text-white font-normal rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
              onClick={() => {
                setIsConfirmOpen(false);
              }}
            >
              Annulla
            </button>
            <button
              className="px-3 py-1 bg-gray-200 text-gray-800 font-normal rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-400"
              onClick={() => {
                setIsConfirmOpen(false);
                setIsOpen(false);
                setEditorSummary(embedConfig.summary!); // Rinizializza il summary nell'editor
              }}
            >
              Conferma
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

// Componente per la configurazione dell'Editor (in particolare la sua toolbar)
const editorConfig = () : EditorConfig => {
  const config: EditorConfig = {
    plugins: [
      Essentials,
      Paragraph,
      RemoveFormat,
      FindAndReplace,
      SourceEditing,
      GeneralHtmlSupport,
      FullPage,
      Heading,
      Font,
      Bold,
      Italic,
      Underline,
      Strikethrough,
      Subscript,
      Superscript,
      SpecialCharacters,
      SpecialCharactersEssentials,
      Code,
      Alignment,
      Link,
      AutoLink,
      Indent,
      IndentBlock,
      Table,
      TableToolbar,
      TableCaption,
      TableProperties,
      TableCellProperties,
      TableColumnResize,
      HorizontalLine,
      PasteFromOffice,
    ],
    fontSize: {
      options: ["default", 11, 13, 15, 17, 19, 21, 23],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
      ],
    },
    toolbar: {
      items: [
        "undo",
        "redo",
        "removeFormat",
        "findAndReplace",
        "sourceEditing",
        "|",
        "heading",
        "fontSize",
        {
          icon: "text",
          label: "Fonts",
          withText: false,
          items: ["fontFamily", "fontColor", "fontBackgroundColor"],
        },
        "|",
        "bold",
        "italic",
        "underline",
        {
          icon: "threeVerticalDots",
          label: "More basic styles",
          withText: false,
          items: ["strikethrough", "superscript", "subscript", "code"],
        }, // '|',
       // breakpoint
        "alignment:left",
        "alignment:right",
        "alignment:center",
        "alignment:justify",
        "outdent",
        "indent",
        "|",
        "link",
        "insertTable",
        "horizontalLine",
        "|",
      ],
      shouldNotGroupWhenFull: true,
    },
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true,
        },
      ],
      disallow: [
        /* HTML features to disallow */
      ],
    },
    table: {
      contentToolbar: [
        "toggleTableCaption",
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
      tableProperties: {
        // Configuration of the TableProperties plugin
        // ...
      },
      tableCellProperties: {
        // Configuration of the TableCellProperties plugin
        // ...
      },
    },
  };

  return config;
};

// Viene richiamato nel caso venga aperta una Wiki senza contenuto (mostrata solo ad utenti non SuperAdmin)
const EmptySummary = (props: { onClick: (b: boolean) => void }) => {
  const { onClick } = props;

  return (
    <DialogContent
      customCloseButton
      className="sm:max-w-[400px] p-4 bg-white shadow-md rounded-md"
    >
      <DialogTitle hidden>Attenzione</DialogTitle>
      <p className="text-base font-medium text-gray-700">
        Al momento non è presente una descrizione per questo report
      </p>
      <DialogDescription aria-describedby={undefined} />

      <div className="mt-3 flex justify-end">
        <button
          className="px-3 py-1 bg-titlecolor text-white text-sm font-normal rounded-md hover:bg-bgtooltip focus:outline-none focus:ring-blue-200"
          onClick={() => {
            onClick(false);
          }}
        >
          Ok
        </button>
      </div>
    </DialogContent>
  );
};

export default Wiki;
