import { DashboardContext } from "@/stores/dashboards";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { useContext } from "react";
import { EmbedConfig } from "@/services/powerbi-service";
import "../../assets/styles/index.css";
import Wiki from "./wiki";
import { SpinnerLoading } from "./loadings";
import Footer from "./footer";

function EmbedConfigComponent(props: {
  embed: EmbedConfig;
  handlePageChanged: (b: boolean) => void;
}) {
  const { embed, handlePageChanged } = props;

  return (
    <PowerBIEmbed 
      key={embed.artifactId}
      embedConfig={{
        type: "report",
        id: embed.artifactId,
        embedUrl: embed.embedUrl,
        accessToken: embed.token,
        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed

        permissions: models.Permissions.Read,
        viewMode: models.ViewMode.View,

        settings: {
          filterPaneEnabled: false, // Disabilita il pannello dei filtri
          layoutType: models.LayoutType.Custom,
          customLayout: {
            displayOption: models.DisplayOption.FitToPage,
          },
          background: models.BackgroundType.Transparent,
          panes: {
            bookmarks: {
              visible: false, // Nascondi il pannello dei bookmarks
            },
            fields: {
              expanded: false, // Nascondi il pannello dei campi
            },
            filters: {
              expanded: false, // Nascondi il pannello dei filtri
              visible: false,
            },
            pageNavigation: {
              visible: true, // Disabilita la navigazione tra le pagine
              position: models.PageNavigationPosition.Left,
            },
            selection: {
              visible: false, // Nascondi il pannello di selezione
            },
            syncSlicers: {
              visible: false, // Disabilita il pannello di sincronizzazione dei filtri
            },
            visualizations: {
              expanded: false, // Nascondi il pannello delle visualizzazioni
            },
          },
        },
      }}
      cssClassName="my-report"
      eventHandlers={
        new Map([
          ["loaded", function () {}],
          [
            "rendered",
            function () {
              console.debug("Power Bi Component - rendered")
              handlePageChanged(true);
            },
          ],
          [
            "error",
            function (event) {
              console.error("PowerBI Error:", event);
            },
          ],
        ])
      }
    />
  );
}

function Monitor(props: {
  visible: boolean;
  setMoniotrVisible: (b: boolean) => void;
}) {
  const { visible, setMoniotrVisible } = props;
  const dashboardContext = useContext(DashboardContext);
  if (!dashboardContext) {
    throw new Error("DashboardContext must be used within DashboardProvider");
  }
  const { embedConfig } = dashboardContext;

  function handlePageChanged() {
    setMoniotrVisible(true);
  }

  return (
    <div>
      {/*Loading durante il caricamento*/}
      {visible ? (
        <span className="sr-only"></span>
      ) : (
        <div className="w-full h-[89.7vh] bg-white absolute opacity-100 flex justify-center items-center z-50">
          <SpinnerLoading />
        </div>
      )}

      <div className="relative">
        <EmbedConfigComponent
          embed={embedConfig}
          handlePageChanged={handlePageChanged} // Quando il report è caricato con successo rimetto a true la visibilità del report
        />
        <Wiki />
        <Footer />
      </div>
    </div>
  );
}

export default Monitor;

/*
useEffect(() => {
    const targetNode = document.querySelector(".my-report");
    if (!targetNode) return;

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "attributes") {
          const isInFocusMode = targetNode.classList.contains("popOutBar"); // Verifica la classe Focus Mode
          if (isInFocusMode) {
            console.log("Entrato in Focus Mode");
          } else {
            console.log("Uscito da Focus Mode");
          }
        }
      }
    });

    observer.observe(targetNode, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect(); // Cleanup all'unmount
  }, []);
*/