import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  useAuthState,
  useAuthDispatch,
  setAuthentication,
  AuthAction,
} from "@/stores/user-store";
import { authService, AuthResponse } from "@/services/auth-service";

//Function that return an AxiosInstance (for example used in users.tsx)
export const useAxiosInstance = (): AxiosInstance => {
  const userState = useAuthState();
  const userDispatch = useAuthDispatch();

  //Initialization of base instance
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
    },
  });

  instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (!config.headers) {
        config.headers = {};
      }
      if (userState.jwt) {
        config.headers.Authorization = `Bearer ${userState.jwt}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );

  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (err) => {
      const originalConfig = err.config;
      if (
        err.response &&
        err.response.status === 401 &&
        !originalConfig._retry
      ) {
        originalConfig._retry = true;
        try {
          const refreshSucceeded = await refreshToken(userDispatch);
          if (refreshSucceeded) {
            originalConfig.headers.Authorization = `Bearer ${userState.jwt}`;
            return instance(originalConfig);
          } else {
            window.location.href = `/`;
            console.log(err);
            return Promise.reject(err);
          }
        } catch (err) {
          console.error("Error refreshing token:", err);
          return Promise.reject(err);
        }
      }
      return Promise.reject(err);
    }
  );

  const refreshToken = async (
    dispatch: React.Dispatch<AuthAction>
  ): Promise<boolean> => {
    try {
      const response = await authService.refreshToken();
      const result: AuthResponse = {
        username: response.data.username,
        role: response.data.role,
        tenant: response.data.tenant,
        jwt: response.data.jwt,
        expires: new Date(response.data.expires),
        status: response.data.status,
        message: response.data.message,
      };
      setAuthentication(dispatch, result);
      return true;
    } catch (error: any) {
      console.error("Error refreshing token:", error);
      return false;
    }
  };

  return instance;
};

// Il servizio di base per altre classi di servizi
export class BaseService {
  protected instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }
}
