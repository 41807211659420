import { useContext, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "../shadcn/ui/dialog";
import { UserContext } from "@/stores/users";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../shadcn/ui/tooltip";
import { Button } from "../shadcn/ui/button";
import { FaUserMinus, FaUserPlus } from "react-icons/fa";

const EnableConfirm = (props: { username: string; enabled: boolean }) => {
  const { username, enabled } = props;

  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext must be used within UserProvider");
  }
  const { updateEnabled } = userContext;

  const [isOpen, setIsOpen] = useState(false); // Stato per il Dialog principale
  const [isConfirmOpen, setIsConfirmOpen] = useState(false); // Stato per il secondo Dialog, cioè la conferma prima della chiusura senza salvataggio

  const [requestMessage, setRequestMessage] = useState<{
    status: number;
    message: string;
  }>();

  async function onSubmit() {
    const data: { username: string; enabled: boolean } = {
      username: username,
      enabled: !enabled,
    };
    updateEnabled(data)
      .then((res) => {
        setRequestMessage(res);
      })
      .catch((error) => console.error(`something went wrong: ${error}`));
      setIsConfirmOpen(true);
  }

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <DialogTrigger asChild>
                <Button
                  className="border-bordermenu hover:bg-bgtooltip"
                  variant="outline"
                  size="sm"
                  onClick={() => {}}
                >
                  {enabled ? (
                    <FaUserMinus className="text-red-500" />
                  ) : (
                    <FaUserPlus className="text-green-500" />
                  )}
                </Button>
              </DialogTrigger>
            </TooltipTrigger>
            <TooltipContent>
              <p>{enabled ? "Disable user" : "Enable user"}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <DialogContent
          customCloseButton
          className="sm:max-w-[500px] p-8 bg-white shadow-md rounded-md"
        >
          <DialogTitle hidden>Attenzione</DialogTitle>
          <p className="text-base font-medium text-gray-700">
            {enabled === true
              ? `Vuoi proseguire a disabilitare l'utente \n"${username}"?`
              : `Vuoi proseguire a riabilitare l'utente \n"${username}?"`}
          </p>
          <DialogDescription className="flex space-x-2 justify-center pt-2">
            <button
              className="px-5 py-2 bg-gray-200 text-gray-800 font-normal rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-400"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Annulla
            </button>

            <button
              className="px-5 py-2 bg-blue-500 text-white text-sm font-normal rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
              onClick={() => {
                onSubmit();
                setIsOpen(false);
  
              }}
            >
              Conferma
            </button>
          </DialogDescription>
        </DialogContent>
      </Dialog>

      <Dialog open={isConfirmOpen} onOpenChange={setIsConfirmOpen}>
        <DialogContent
          customCloseButton
          className="sm:max-w-[400px] p-4 bg-white shadow-md rounded-md"
        >
          <DialogTitle hidden>Attenzione</DialogTitle>
          {requestMessage?.status === 200 ? (
            <p className="text-center">
              {requestMessage.message}
            </p>
          ) : requestMessage?.status ? (
            <p className="text-red-500 self-center">{requestMessage.message}</p>
          ) : (
            ""
          )}{" "}
          <DialogDescription className="mt-3 flex justify-end">
            <button
              className="px-3 py-1 bg-blue-500 text-white text-center text-sm font-normal rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
              onClick={() => {
                setIsConfirmOpen(false);
            }}
            >
              Ok
            </button>
          </DialogDescription>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EnableConfirm;
