const Spinner: React.FC = () => {
  return (
    <>
      {/* un div con i bordi colorati, rotondi che gira su se stesso */}
      <div className=" w-4 h-4 border-2 border-white border-t-blue-400 rounded-full animate-spin"></div>
    </>
  );
};

export default Spinner;
