import { Button } from "@/components/shadcn/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/shadcn/ui/dialog";
import { Input } from "@/components/shadcn/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/shadcn/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FaKey, FaEye, FaEyeSlash } from "react-icons/fa";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/shadcn/ui/tooltip";
import { useContext, useEffect, useReducer, useState } from "react";
import { UserContext } from "@/stores/users";
import Spinner from "./spinner";

//Error messages schema, in case is empty
export const formChangePassword = z
  .object({
    oldPassword: z.string().min(1, {
      message: "Questo campo non può essere vuoto",
    }),
    newPassword: z.string().min(1, {
      message: "Questo campo non può essere vuoto",
    }),
    confirmNewPassword: z.string().min(1, {
      message: "Questo campo non può essere vuoto",
    }),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    message: "Le password non corrispondono",
    path: ["confirmNewPassword"], //The error message focus on the confirm password field
  });

export default function ChangePassword({ username }: { username: string }) {
  // State to track dialog open/close
  const [isOpen, setIsOpen] = useState(false);
  // State to save request status response
  const [requestMessage, setRequestMessage] = useState<{
    status: number;
    message: string;
  }>();

  //Make possible to view password if user wants
  interface IShowPassword {
    oldPassword: boolean;
    newPassword: boolean;
    confirmNewPassword: boolean;
  }
  //Implementation of the reducer, make possible to change data in base of action type
  const showPasswordReducer = (
    state: IShowPassword,
    action: { type: string; name: string }
  ): IShowPassword => {
    switch (action.type) {
      case "show_old_password":
        return { ...state, [action.name]: !state.oldPassword };
      case "show_new_password":
        return { ...state, [action.name]: !state.newPassword };
      case "show_confirm_new_password":
        return { ...state, [action.name]: !state.confirmNewPassword };
      default:
        return state;
    }
  };
  //useReducer to control the password's visibility
  const [showPassword, dispatchShowPassword] = useReducer(showPasswordReducer, {
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  //Here we use the UserContext we declared in users.tsx
  const userContext = useContext(UserContext);

  //make sure that userContext exist and is not null
  if (!userContext) {
    throw new Error("UserContext must be used within UserProvider");
  }
  //destructoring userContext and obtain changePassword function
  const { changePassword, spinner } = userContext;

  //Initialize base values with empty string
  const form = useForm<z.infer<typeof formChangePassword>>({
    resolver: zodResolver(formChangePassword),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  // Reset form values when dialog is closed
  useEffect(() => {
    if (isOpen) {
      form.reset({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      });
      setRequestMessage(undefined);
    }
  }, [isOpen, form]);

  //Function executed when change button is pressed, try changing password and return a status object
  async function onSubmit(data: z.infer<typeof formChangePassword>) {
    changePassword(data, username)
      .then((res) => {
        setRequestMessage(res);
        res.status === 200 &&
          form.reset({
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          });
      })
      .catch((error) => console.error(`something went wrong: ${error}`));
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <DialogTrigger asChild>
              <Button
                className="border-bordermenu hover:bg-bgtooltip"
                variant="outline"
                size="sm"
                onClick={() => {}}
              >
                <FaKey className="text-blue-500" />
              </Button>
            </DialogTrigger>
          </TooltipTrigger>
          <TooltipContent>
            <p>Change password</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <DialogContent className="sm:max-w-[425px] border-bordermenu">
        <DialogHeader>
          <DialogTitle>CHANGE PASSWORD</DialogTitle>
          <DialogDescription>
            Cambia la password dell'utente. Click su "Change" quando hai fatto.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col p-6 gap-4 h-80"
          >
            <FormField
              control={form.control}
              name="oldPassword"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <Input
                        className="w-[300px] focus:outline-none pr-7"
                        type={showPassword.oldPassword ? "text" : "password"}
                        placeholder="Old password"
                        {...field}
                        onBlur={() => form.trigger("oldPassword")}
                        onFocus={() => form.clearErrors("oldPassword")}
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-6 pr-3 flex items-center text-sm leading-5"
                        onClick={() =>
                          dispatchShowPassword({
                            type: "show_old_password",
                            name: "oldPassword",
                          })
                        }
                      >
                        {showPassword.oldPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </FormControl>
                  <div className="h-3">
                    <FormMessage className="text-red-500" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="newPassword"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <Input
                        className="w-[300px] focus:outline-none pr-7"
                        type={showPassword.newPassword ? "text" : "password"}
                        placeholder="New password"
                        {...field}
                        onBlur={() => form.trigger("newPassword")}
                        onFocus={() => form.clearErrors("newPassword")}
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-6 pr-3 flex items-center text-sm leading-5"
                        onClick={() =>
                          dispatchShowPassword({
                            type: "show_new_password",
                            name: "newPassword",
                          })
                        }
                      >
                        {showPassword.newPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </FormControl>
                  <div className="h-3">
                    <FormMessage className="text-red-500" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmNewPassword"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <Input
                        className="w-[300px] focus:outline-none pr-7"
                        type={
                          showPassword.confirmNewPassword ? "text" : "password"
                        }
                        placeholder="Confirm new password"
                        {...field}
                        onBlur={() => form.trigger("confirmNewPassword")}
                        onFocus={() => form.clearErrors("confirmNewPassword")}
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-6 pr-3 flex items-center text-sm leading-5"
                        onClick={() =>
                          dispatchShowPassword({
                            type: "show_confirm_new_password",
                            name: "confirmNewPassword",
                          })
                        }
                      >
                        {showPassword.confirmNewPassword ? (
                          <FaEyeSlash />
                        ) : (
                          <FaEye />
                        )}
                      </button>
                    </div>
                  </FormControl>
                  <div className="h-3">
                    <FormMessage className="text-red-500" />
                  </div>
                </FormItem>
              )}
            />
            <Button
              type="submit"
              variant="outline"
              className="w-1/2 self-center border-bordermenu hover:bg-bghoverbutton"
            >
              {spinner ? <Spinner /> : "Change"}
            </Button>
            {requestMessage?.status === 200 ? (
              <p className="text-green-500 self-center">
              {requestMessage.message}
            </p>
            ) : requestMessage?.status ? (
              <p className="text-red-500 self-center">
                {requestMessage.message}
              </p>
            ) : (
              ""
            )}  
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
