import Dashboard from "@/components/pages/dashboard";
import Login from "@/components/pages/login";
import { NotFoundPage } from "@/components/pages/notfoundpage";
import Users from "@/components/pages/user";
import { AuthResponse, authService } from "@/services/auth-service";
import { DashboardProvider } from "@/stores/dashboards";
import {
  AuthAction,
  AuthState,
  setAuthentication,
  useAuthDispatch,
  useAuthState,
} from "@/stores/user-store";
import { Dispatch, useEffect, useState } from "react";
import { createBrowserRouter, Navigate, useLocation } from "react-router-dom";
import { LoadingToDashborads } from "@/components/elements/loadings";

async function refreshToken() {
  let result: AuthResponse | null;

  try {
    const response = await authService.refreshToken();
    result = {
      username: response.data.username,
      role: response.data.role,
      tenant: response.data.tenant,
      jwt: response.data.jwt,
      expires: new Date(response.data.expires),
      status: response.data.status,
      message: response.data.message,
    };
  } catch (error: any) {
    result = null;

    if (error.response) {
      console.error(
        `router.refreshToken() - request made and response received, logging error.response`
      );
      console.error(error.response);
    } else if (error.request) {
      console.error(
        `router.refreshToken() - request made but no response received, logging error.request`
      );
      console.error(error.request);
    } else {
      console.error(
        `router.refreshToken() - error setting up the request, logging error.message`
      );
      console.error("Error", error.message);
    }
  }

  return result;
}

async function routeGuard(
  userStore: AuthState,
  userDispatch: Dispatch<AuthAction>
): Promise<boolean> {
  //init
  let authSucceeded = false;
  let refreshNeeded = false;

  if (userStore.jwt && userStore.expires) {
    console.debug(`routeGuard - user.jwt found`);

    if (userStore.expires > new Date()) {
      const expirationDate = userStore.expires.toLocaleString(
        "it-IT",
        dateTimeFormat
      );
      console.debug(
        `routeGuard - user.jwt is valid [expires on ${expirationDate}]`
      );
      console.debug("routeGuard - proceeding to the route");

      //se il jwt è valido procede verso la pagina richiesta
      authSucceeded = true;
      refreshNeeded = false;
    } else {
      const expirationDate = userStore.expires.toLocaleString(
        "it-IT",
        dateTimeFormat
      );
      console.debug(`routeGuard - user.jwt is expired [on ${expirationDate}]`);
      console.debug("routeGuard - trying to refresh the token");

      //se il jwt è scaduto tenta il refresh
      authSucceeded = false;
      refreshNeeded = true;
    }
  } else {
    console.debug(`routeGuard - user.jwt not found`);
    console.debug("routeGuard - trying to refresh the token");

    //se non trova il jwt tenta il refresh
    authSucceeded = false;
    refreshNeeded = true;
  }

  if (refreshNeeded)
    await refreshToken().then(async (result) => {
      //console.debug(`routeGuard - refreshToken response [${JSON.stringify(result)}]`)

      if (result && result.username && result.role && result.jwt) {
        console.debug(`routeGuard - setting user state`);
        setAuthentication(userDispatch, result);

        //procede verso la pagina richiesta
        authSucceeded = true;
        refreshNeeded = false;
      } else {
        console.debug(`routeGuard - token refresh failed`);
        console.debug("routeGuard - redirecting to login");

        //redirige la navigazione alla pagina di login
        authSucceeded = false;
        refreshNeeded = false;
      }
    });

  return authSucceeded;
}

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
  
  // salvo l'url prima dell'autenticazione, così una volta conclusa utilizzo questo per continuare la navigazione. 
  const location = useLocation();

  // Da passare al routeGuard
  const userStore = useAuthState();
  const userDispatch = useAuthDispatch();

  useEffect(() => {
    (async () => {
      const result = await routeGuard(userStore, userDispatch); // Aspetta che useRouteGuard verifichi l'autenticazione
      setIsAuthorized(result);
    })();
  }, []);

  // Capitava che il codice arrivasse all'ultimo return senza che isAuthorized fosse valorizzato (true/false). Così il codice funziona
  if (isAuthorized === null) {
    return <LoadingToDashborads />; // Ritorna la pagina di caricamento con lo spinner
  }

  return isAuthorized ? children : <Navigate to="/login" replace={true} state={{ redirectTo: location.pathname }} />;
};

export const Router = createBrowserRouter([
  {
    path: "/",

    // Punta subito alla pagina dashboards. Sarà il PrivateRouter a rimbalzare al login nel caso non sia autorizzato
    element: <Navigate to="/dashboards" replace={true} />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/dashboards/:displayname?",
    element: (
      <ProtectedRoute>
        <DashboardProvider>
          <Dashboard />
        </DashboardProvider>
      </ProtectedRoute>
    ),
  },
  {
    path: "/users",
    element: (
      <ProtectedRoute>
          <Users />
      </ProtectedRoute>
    ),
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

// Utilzzato nella funzione RouteGuard
const dateTimeFormat: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};
