import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Avatar, AvatarFallback } from "@/components/shadcn/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/shadcn/ui/dropdown-menu";
import { LogOut, Users } from "lucide-react";
import {
  clearState,
  useAuthDispatch,
  useAuthSelectors,
  useAuthState,
} from "@/stores/user-store";
import { authService } from "@/services/auth-service";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "../shadcn/ui/dialog";
import { boolean } from "zod";

const LoggedUser: React.FC = () => {
  const authState = useAuthState();
  const authDispatch = useAuthDispatch();
  const { isAuthenticated, isSuperAdmin } = useAuthSelectors();
  const navigate = useNavigate();

  let locationPath = useLocation();
  const [location] = useState(locationPath);
  const [isOpenLogoutDialog, setIsOpenLogoutDialog] = useState(false); // Stato da passare al Dialog di Logout

  const handleLogout = async () => {
    console.debug(`LoggedUser.logout() - logging out`);
    console.debug(`LoggedUser.logout() - trying to revoke the refresh token`);
    try {
      // awaiting token is revoked
      const isSuccess = await authService.revokeToken();
      if (isSuccess) {
        console.debug(`LoggedUser.logout() - token revoked`);

        console.debug(`LoggedUser.logout() - clearing user store`);
        clearState(authDispatch);

        console.debug(`LoggedUser.logout() - logout completed`);
      } else {
        console.error(`LoggedUser.logout() - ERROR trying to revoke the token`);
      }
    } catch (error) {
      console.error(`Error revoking token: ${error}`);
    }

    console.debug(`LoggedUser.logout() - redirecting to login form`);
    // if loggout is succesful and token revoked navigate to login page
    navigate("/login");
  };

  return (
    <div className="flex items-center justify-center mr-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button className="flex items-center space-x-2 hover:text-titlecolor outline-none">
          <div>
            <div className="text-xs">{authState.username}</div>
            <div className="text-xxs">{authState.tenant}</div>
          </div>

          <div className="flex items-center rounded focus:outline-none">
            {/* if user is authenticated show avatar image */}
            {isAuthenticated && (
              <Avatar className="w-8 h-8">
                {/* take the first letter of username and render it near username */}
                <AvatarFallback className="bg-bgtooltip text-black">
                  {authState.username &&
                    authState.username.charAt(0).toUpperCase()}
                </AvatarFallback>
              </Avatar>
            )}
            {/* if authenticated show username account */}
            {/* <p className="ml-1">{isAuthenticated ? (authState.username) : 'Not Authenticated'}</p> */}
          </div>
          </button>
        </DropdownMenuTrigger>
        {/* if authenticated render the select menu */}
        {isAuthenticated && (
          <DropdownMenuContent className="w-40 mr-1">
            <DropdownMenuItem className="pointer-events-none">
              <p className="ml-1">
                {isAuthenticated ? authState.username : "Not Authenticated"}
              </p>
            </DropdownMenuItem>
            <DropdownMenuSeparator />

            {isSuperAdmin &&
              (location.pathname === "/users" ? (
                <DropdownMenuItem className="pointer-events-none">
                  <button className="flex w-full pointer-events-none">
                    <Users className="mr-2 h-4 w-4" />
                    <span>Utenti</span>
                  </button>
                </DropdownMenuItem>
              ) : (
                <DropdownMenuItem>
                  <button
                    onClick={() => {
                      navigate("/users");
                    }}
                    className="flex w-full"
                  >
                    <Users className="mr-2 h-4 w-4" />
                    <span className="pointer-events-auto">Utenti</span>
                  </button>
                </DropdownMenuItem>
              ))}

            <DropdownMenuItem>
              <button
                className="flex w-full"
                onClick={() => {
                  setIsOpenLogoutDialog(true);
                }}
              >
                <LogOut className="mr-2 h-4 w-4" />
                <span className="pointer-events-auto">Esci</span>
              </button>
            </DropdownMenuItem>
          </DropdownMenuContent>
        )}
      </DropdownMenu>

      {/* Dialog per il Logout */}
      <Dialog open={isOpenLogoutDialog} onOpenChange={setIsOpenLogoutDialog}>
        <DialogContent
          customCloseButton
          className="sm:max-w-[400px] p-4 bg-white shadow-md rounded-md"
        >
          <DialogTitle hidden className="text-center">
            Attenzione
          </DialogTitle>
          <p className="text-base font-medium text-gray-700">
            Proseguire con il logout?
          </p>
          <DialogDescription aria-describedby={undefined} />

          <div className="mt-3 flex justify-end space-x-1">
            <button
              className="px-3 py-1 bg-gray-200 text-gray-800 font-normal rounded-md hover:bg-gray-300 focus:outline-none  focus:ring-gray-400"
              onClick={() => {
                setIsOpenLogoutDialog(false);
              }}
            >
              Annulla
            </button>
            <button
              className="px-3 py-1 bg-titlecolor text-white font-normal rounded-md hover:bg-bghoverbutton focus:outline-none focus:ring focus:ring-blue-200"
              onClick={() => {
                handleLogout();
                navigate("/login");
              }}
            >
              Conferma
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LoggedUser;
