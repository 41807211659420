import { BaseService } from "@/services/base-service";
import { AxiosInstance } from "axios";

// TODO ARCH: revisione; quali altri campi devono essere sempre valorizzati?
export interface Dashboard {
  id: string; // | undefined
  displayName: string; // | undefined
  webUrl: string | undefined;
  embedUrl: string | undefined;
  summary: string | undefined;
  product: string | undefined;
  category: string | undefined;
}

export interface Report {
  id: string; // | undefined
  displayName: string; // | undefined
  webUrl: string | undefined;
  embedUrl: string | undefined;
  summary: string | undefined;
  product: string | undefined;
  category: string | undefined;
  code: string | undefined;
}

export interface EmbedConfig {
  artifactId: string | undefined;
  artifactType: string | undefined;
  displayName: string | undefined;
  product: string | undefined;
  category: string | undefined;
  webUrl: string | undefined;
  embedUrl: string | undefined;
  summary: string | undefined;
  tokenId: string | undefined;
  token: string | undefined;
  expiration: Date | string;
}

class PowerBIService extends BaseService {
  getDashboards(category: string) {
    return this.instance.get<Dashboard[]>(`powerbi/dashboards/${category}`);
  }

  getReports() {
    return this.instance.get<Report[]>(`powerbi/reports`);
  }

  embedReport(reportId: string) {
    return this.instance.get<EmbedConfig>(`powerbi/embed-report/${reportId}`);
  }

  embedDashboard(dashboardId: string) {
    return this.instance.get<EmbedConfig>(
      `powerbi/embed-dashboard/${dashboardId}`
    );
  }

  setReportSummary(reportId: string, value: string) {
    return this.instance.post<Report>(
      `powerbi/reports/${reportId}/summary`,
      value
    );
  }

  setDashboardSummary(dashboardId: string, value: string) {
    return this.instance.post<Dashboard>(
      `powerbi/dashboards/${dashboardId}/summary`,
      value
    );
  }
}
export const powerBIService = (axiosInstance: AxiosInstance) =>
  new PowerBIService(axiosInstance);
