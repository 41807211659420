function Footer() {
    return (
        <div className="fixed bottom-2 left-3 text-gray-500 text-xs pointer-events-none">
          ©2024 FastCode SpA
        </div>
    )
}

export default Footer;

// return (
//     <footer className="mt-auto w-full">
//         <Separator />
//         <p className="text-center py-4 text-sm">Copyright ©2024 FastCode SpA</p>
//     </footer>
// )