import { Button } from "@/components/shadcn/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/shadcn/ui/dialog";
import { Input } from "@/components/shadcn/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/shadcn/ui/form";
import { Label } from "../shadcn/ui/label";
import { z } from "zod";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import Spinner from "./spinner";
import { useContext, useState, useReducer, useEffect } from "react";
import { UserContext } from "@/stores/users";
import { FormSelection, FormSelectionElement } from "./formSelection";
import { Checkbox } from "../shadcn/ui/checkbox";

const allRoles: FormSelectionElement[] = [
  {
    code: "USR",
    name: "User",
  },
  {
    code: "ADM",
    name: "Admin",
  },
];

const allTenants: FormSelectionElement[] = [
  {
    code: "Aruba",
    name: "Aruba",
  },
  {
    code: "Fastcode",
    name: "Fastcode",
  },
];

//Error messages schema, in case username/password is empty
export const formCreateUSer = z.object({
  username: z.string().min(1, {
    message: "Lo username non può essere vuoto",
  }).min(8, {message:"Lo username deve avere almeno 8 caratteri"})
  
  ,
  password: z.string().min(1, {
    message: "La password non può essere vuota",
  }).min(8, {message:"La password deve essere più lunga"}),
  role: z.string(),
  tenant: z.string(),
  enabled: z.boolean(),
});

//Make possible to view password if user wants
interface IShowPassword {
  password: boolean;
}

export default function CreateUser() {
  //Initialize base values with empty string
  const form = useForm<z.infer<typeof formCreateUSer>>({
    resolver: zodResolver(formCreateUSer),
    defaultValues: {
      username: "",
      password: "",
      role: "User",
      tenant: "",
      enabled: true,
    },
  });

  const { setValue } = form;

  //Here we use the UserContext we declared in users.tsx
  const userContext = useContext(UserContext);

  //make sure that userContext exist and is not null
  if (!userContext) {
    throw new Error("UserContext must be used within UserProvider");
  }
  //destructoring userContext and obtain createUser function
  const { createUser, spinner } = userContext;

  //Function executed when change button is pressed, try creating user and return a status object
  async function onSubmit(data: z.infer<typeof formCreateUSer>) {
    createUser(data)
      .then((res) => {
        setRequestMessage(res);
        res.status === 200 &&
          form.reset({
            username: "",
            password: "",
            role: "User",
            tenant: "",
            enabled: true,
          });
      })
      .catch((error) => console.error(`something went wrong: ${error}`));
  }

  const handleRoleSelect = (value: string) => {
    setValue("role", value);
  };

  const handleTenantSelect = (value: string) => {
    setValue("tenant", value);
  };

  const handleEnabledSelect = (value: boolean) => {
    setValue("enabled", value);
  };

  //Implementation of the reducer, make possible to change data in base of action type
  const showPasswordReducer = (
    state: IShowPassword,
    action: { type: string; name: string }
  ): IShowPassword => {
    switch (action.type) {
      case "show_password":
        return { ...state, [action.name]: !state.password };
      default:
        return state;
    }
  };
  //useReducer to control the password's visibility
  const [showPassword, dispatchShowPassword] = useReducer(showPasswordReducer, {
    password: true,
  });
  const [isOpen, setIsOpen] = useState(false);

  const [requestMessage, setRequestMessage] = useState<{
    status: number;
    message: string;
  }>();

  // Reset form values when dialog is closed
  useEffect(() => {
    if (isOpen) {
      form.reset({
        username: "",
        password: "",
        role: "User",
        tenant: "Fastcode",
        enabled: true,
      });
      setRequestMessage(undefined);
    }
  }, [isOpen, form]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          onClick={() => {}}
          variant="outline"
          className="border-bordermenu hover:bg-bghoverbutton"
        >
          Create a user
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] border-bordermenu">
        <DialogHeader>
          <DialogTitle className="mx-auto justify-center items-center">Create a user</DialogTitle>
          <DialogDescription >
            Crea un nuovo user. Click su "Create" quando hai fatto.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col p-6 gap-4 w-[350px] mx-auto justify-center items-center"
          >
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      className="w-[300px]"
                      placeholder="Username"
                      {...field}
                      onBlur={() => form.trigger("username")}
                      onFocus={() => form.clearErrors("username")}
                    />
                  </FormControl>
                  <div className="h-3">
                    <FormMessage className="text-red-500" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <Input
                        className="w-[300px]"
                        type={showPassword.password ? "text" : "password"}
                        placeholder="Password"
                        {...field}
                        onBlur={() => form.trigger("password")}
                        onFocus={() => form.clearErrors("password")}
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-6 pr-3 flex items-center text-sm leading-5"
                        onClick={() =>
                          dispatchShowPassword({
                            type: "show_password",
                            name: "password",
                          })
                        }
                      >
                        {showPassword.password ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                  </FormControl>
                  <div className="h-3">
                    <FormMessage className="text-red-500" />
                  </div>
                </FormItem>
              )}
            />

            <div className="flex space-x-5">
              <FormField
                control={form.control}
                name="role"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormSelection
                        onSelect={handleRoleSelect}
                        allSelection={allRoles}
                      />
                    </FormControl>
                    <div className="h-3">
                      <FormMessage className="text-red-500" />
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="tenant"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormSelection
                        onSelect={handleTenantSelect}
                        allSelection={allTenants}
                      />
                    </FormControl>
                    <div className="h-3">
                      <FormMessage className="text-red-500" />
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="enabled"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="flex space-x-2 items-center">
                      <Label className="text-sm font-normal">Enabled: </Label>
                      <Checkbox
                        defaultChecked
                        onCheckedChange={handleEnabledSelect}
                      />
                    </div>
                  </FormControl>
                  <div className="h-3">
                    <FormMessage className="text-red-500" />
                  </div>
                </FormItem>
              )}
            />

            <Button
              type="submit"
              variant="outline"
              className="w-1/2 self-center border-bordermenu hover:bg-bghoverbutton"
            >
              {spinner ? <Spinner /> : "Create"}
            </Button>
            {requestMessage?.status === 200 ? (
              <p className="text-green-500 self-center">
              {requestMessage.message}
            </p>
            ) : requestMessage?.status ? (
              <p className="text-red-500 self-center">
                {requestMessage.message}
              </p>
            ) : (
              ""
            )}

          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
