import { useEffect } from "react";
import { getUserStore } from "@/stores/user-store";

export const useTenantStyles = () => {
  const { useAuthSelectors } = getUserStore();
  const { tenant } = useAuthSelectors();

  useEffect(() => {
    let root = document.documentElement;
    if (tenant === 'FASTCODE') {
      root.style.setProperty('--title-color', '84 65% 44%')
      root.style.setProperty('--menu', '84 65% 44%')
      root.style.setProperty('--bghoverbutton', '84 65% 82%')
      root.style.setProperty('--bgtooltip', '84 65% 89%')
      root.style.setProperty('--borderinput', '84 65% 44%')
    }
    else if (tenant === 'ARUBA') {
      root.style.setProperty('--title-color', '21 96% 45%')
      root.style.setProperty('--menu', '21 96% 45%')
      root.style.setProperty('--bghoverbutton', '21 96% 78%')
      root.style.setProperty('--bgtooltip', '21 96% 85%')
      root.style.setProperty('--borderinput', '21 96% 45%')
    }
    else if (tenant === null) {
      root.style.setProperty('--title-color', '0 0% 0%')
      root.style.setProperty('--menu', '216 12% 84%')
      root.style.setProperty('--bghoverbutton', '240 5% 96%')
      root.style.setProperty('--bgtooltip', '240 5% 100%')
      root.style.setProperty('--borderinput', '0 0% 0%')
    }
  }, [tenant]);
};

export default useTenantStyles;