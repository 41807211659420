import axios, { AxiosInstance, AxiosResponse } from "axios";
//import { getUserStore } from '@/stores/user-store'

// Definisco l'interfaccia per le richieste di autenticazione
export interface AuthRequest {
    username: string | null
    password: string | null
}

// Definisco l'interfaccia per le risposte di autenticazione
export interface AuthResponse {
    username: string
    role: string
    tenant: string
    jwt: string
    expires: Date
    status: number
    message: string
}

class AuthService {

    // Istanza per gestire le richieste HTTP
    private instance: AxiosInstance;

    constructor() {
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_BASE_API_URL,
            withCredentials: true,
            headers: {
                "Accept": "application/json"
            }
        //timeout: 5000
        });
    }

    // Metodo per autenticare un utente
    authenticate(request: AuthRequest): Promise<AxiosResponse<AuthResponse>> {
        return this.instance.post<AuthResponse>('/Auth/authenticate', request);
    }

    // Metodo per rigenerare il token di autenticazione
    refreshToken(): Promise<AxiosResponse<AuthResponse>> {
        return this.instance.post<AuthResponse>('/Auth/refresh-token');
    }

    // Metodo per revocare il token di autenticazione
    revokeToken(): Promise<AxiosResponse<void>> {
        //NOTA: rimossa la necessità di autenticazione per la revoca del token.
        //      Adesso è sufficiente essere in possesso del refresh token da revocare,
        //      e ovviamente è necessario che il token sia valido e attivo.
        // const userStore = getUserStore()
        // const config = { headers: { "Authorization": `Bearer ${userStore.jwt}` } }
        // return instance.post('/revoke-token', undefined, config)

        return this.instance.post<void>('/Auth/revoke-token');
    }
}
export const authService = new AuthService()