/* 
    Gli SVG utilizzati sono generati dal sito https://loading.io/, i parametri da modificare sono: 
    StrokeWidth: 6, DashLength: 32, Scale:0.8, Color: *in base al tenant*, Speed: 0.65, Size: 100
    poi esporti come SVG Animated.
*/
import { getUserStore } from "@/stores/user-store";
import LoginHeader from "./headerLogin";

const LoadingFastcode = () => (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      width="100"
      height="100"
      className="shape-rendering: auto; display: block; background: transparent;"
    >
      <g>
        <path
          className="transform:scale(0.8);transform-origin:50px 50px"
          strokeLinecap="round"
          d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
          strokeDasharray="42.76482137044271 42.76482137044271"
          strokeWidth="6"
          stroke="#7fb927"
          fill="none"
        >
          <animate
            values="0;256.58892822265625"
            keyTimes="0;1"
            dur="1.5384615384615383s"
            repeatCount="indefinite"
            attributeName="stroke-dashoffset"
          ></animate>
        </path>
        <g></g>
      </g>
    </svg>
  </div>
);

const LoadingAruba = () => (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      width="100"
      height="100"
      className="shape-rendering: auto; display: block; background: transparent;"
    >
      <g>
        <path
          className="transform:scale(0.8);transform-origin:50px 50px"
          strokeLinecap="round"
          d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
          strokeDasharray="42.76482137044271 42.76482137044271"
          strokeWidth="6"
          stroke="#e15205"
          fill="none"
        >
          <animate
            values="0;256.58892822265625"
            keyTimes="0;1"
            dur="1.5384615384615383s"
            repeatCount="indefinite"
            attributeName="stroke-dashoffset"
          ></animate>
        </path>
        <g></g>
      </g>
    </svg>
  </div>
);

export function SpinnerLoading() {
  const { useAuthSelectors } = getUserStore();
  const { tenant } = useAuthSelectors();

  if (tenant === "FASTCODE") return <LoadingFastcode />;
  if (tenant === "ARUBA") return <LoadingAruba />;
  else return <LoadingFastcode />;
}

export function LoadingToDashborads() {
  const { useAuthSelectors } = getUserStore();
  const { tenant } = useAuthSelectors();

  const TenLoad = () => {
    if (tenant === "FASTCODE") return <LoadingFastcode />;
    if (tenant === "ARUBA") return <LoadingAruba />;
    else return <LoadingFastcode />;
  }; 

  return (
    <>
      <LoginHeader showTitle={false}/>
      <div className="w-full h-[89.7vh] bg-white absolute opacity-100 flex justify-center items-center z-50">
        <TenLoad />
      </div>
    </>
  );
}
